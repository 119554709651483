import styled from 'styled-components';

import { colors } from '../../styles/variables';

// eslint-disable-next-line import/prefer-default-export
export const MapWrapper = styled.div`
  width: 100%;
  height: 100vh;
`;

export const Marker = styled.div`
  position: relative;
  width: 15rem;
  height: 5rem;
  font-size: 1.8rem;
  text-align: center;
  line-height: 5rem;
  background-color: ${colors.lightShade};
  color: ${colors.darkShade};
  padding: 0;
  transform: translate3d(-50%, 0, 0);

  ::before {
    content: '';
    display: block;
    position: absolute;
    width: 1rem;
    height: 1rem;
    background-color: ${colors.lightShade};
    transform: rotate(45deg);
    transform: translate3d(-50%, -50%, 0) rotate(45deg);
    left: 50%;
  }
`;
