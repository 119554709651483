import Styled from 'styled-components';

import { colors, fonts, breakpoints } from '../../styles/variables';

export const Wrapper = Styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => `${height}rem` || '100%'};
  width: 100%;
  background-color: ${colors.darkShade};
  color: ${colors.lightAccent};
  font-family: ${fonts.merriweatherBold};

  @media ${breakpoints.desktop} {
    max-width: 60rem;
  }
`;

export const Header = Styled.div`
  text-align: center;
  width: 100%;

  h2 {
    color: ${colors.lightAccent};
  }
`;

export const Form = Styled.form`
  width: 100%;
  padding: 2rem 0;
`;
