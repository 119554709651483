import React, { useState } from 'react';
import { Wrapper, Header, Form } from './ContactForm.style';

import { H2, Paragraph } from '../../styles/commonStyles';

import useForm from '../../hooks/useForm';
import FormField from '../FormField/FormField';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import Spinner from '../Spinner/Spinner';

const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
);

const stateValidatorSchema = {
  name: {
    required: true,
    validator: {
      func: value => value.length > 1,
      error: 'Napisz jak się nazywasz',
    },
  },
  emailAddress: {
    required: true,
    validator: {
      func: value => validEmailRegex.test(value),
      error: 'Nieprawidłowy adres email',
    },
  },
  message: {
    required: true,
    validator: {
      func: value => value.length > 1,
      error: 'Napisz nam wiadomość',
    },
  },
  phoneNumber: {
    required: false,
  },
  checked: {
    required: true,
  },
};

const ContactForm = () => {
  const stateSchema = {
    name: { value: '', error: '' },
    emailAddress: { value: '', error: '' },
    phoneNumber: { value: '' },
    message: { value: '', error: '' },
    checked: { value: false, error: '' },
  };

  const [rows, setRows] = useState(5);
  const [checkedBox, setCheckedBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  // const recaptchaInstance = useRef(null);

  const onSubmitForm = async values => {
    const { name, emailAddress, message, phoneNumber } = values;

    const isBrowser = typeof window !== 'undefined';

    setIsLoading(true);

    if (isBrowser) {
      try {
        const response = await window.fetch('/.netlify/functions/send-mail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            text: `Wiadomość z formularza kontaktowego Stara Piekarnia\nNadawca: ${name} (${emailAddress})\n${message}`,
            html: `
              <div>
                <h1>Wiadomość z formularza kontaktowego</h1>
                <p>Od ${name} (${emailAddress})</p>
                ${phoneNumber ? `<p>Telefon kontaktowy: ${phoneNumber}</p>` : ''}
                <p>${message}</p>
              </div>
            `,
          }),
        });

        setIsLoading(false);

        if (response.ok) {
          setSuccessMessage(true);
          setTimeout(() => {
            setSuccessMessage(false);
          }, 3000);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  };

  const { values, errors, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm,
  );

  const { name, emailAddress, message, phoneNumber } = values;

  const handleChangeMessage = e => {
    handleOnChange(e);
    const textareaLineHeight = 16;
    const previousRows = e.target.rows;

    e.target.rows = 5; // reset number of rows in textarea

    const currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }

    setRows(currentRows);
  };

  const handleChecked = () => {
    setCheckedBox(c => {
      const checkbox = {
        target: {
          name: 'checked',
          value: !c,
        },
      };
      handleOnChange(checkbox);
      return !c;
    });
  };

  if (successMessage)
    return (
      <Wrapper height={61}>
        <Header>
          <H2>Twoja wiadomość została pomyślnie wysłana</H2>
          <Paragraph>Nasz zespół skontatuje się z Tobą najszybciej jak potrafi :)</Paragraph>
        </Header>
      </Wrapper>
    );

  return (
    <Wrapper>
      {isLoading && <Spinner />}
      <Header>
        <H2>Skontaktuj się z nami!</H2>
      </Header>
      <Form name="contactForm" onSubmit={handleOnSubmit}>
        <FormField
          onInputChange={handleOnChange}
          value={name}
          type="text"
          name="name"
          text="Imię i nazwisko *"
          error={errors.name}
        />

        <FormField
          onInputChange={handleOnChange}
          value={emailAddress}
          type="text"
          name="emailAddress"
          text="E-mail *"
          error={errors.emailAddress}
        />

        <FormField
          onInputChange={handleOnChange}
          value={phoneNumber}
          type="text"
          name="phoneNumber"
          text="Numer telefonu"
        />

        <FormField
          onInputChange={handleChangeMessage}
          value={message}
          name="message"
          rows={rows}
          text="Twoja wiadomość *"
          error={errors.message}
          textarea
        />

        <Checkbox
          text="Wyrażam zgodę na przetwarzanie podanych powyżej danych w celu otrzymywania odpowiedzi. *"
          checked={checkedBox}
          name="agreement"
          error={errors.checked}
          handleSetChecked={handleChecked}
        />

        <Button appearance="invert" block disabled={disable}>
          WYŚLIJ
        </Button>
      </Form>
      {/* <Recaptcha
        ref={recaptchaInstance}
        sitekey="6Le_q8MUAAAAAGZhaCGlEyvW65qe4oACPxHMAx1O"
        size="invisible"
        verifyCallback={response => onSubmitForm(values, response)}
      /> */}
    </Wrapper>
  );
};

export default ContactForm;
