import styled from 'styled-components';

import { colors, breakpoints } from '../../styles/variables';

export const ListBox = styled.div`
  display: flex;
  flex-direction: column;

  @media ${breakpoints.tabletLandscape} {
    flex-direction: row;
    margin: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;

  @media ${breakpoints.tabletLandscape} {
    flex-direction: row;
    align-items: stretch;
  }
`;

export const Column = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  background-color: ${colors.darkShade};
  justify-content: center;
  padding: 2rem;
  text-align: center;

  @media ${breakpoints.tabletLandscape} {
    padding: 5rem;
    justify-content: normal;
    text-align: left;
  }
`;

export const List = styled.ul`
  margin-top: 2rem 0;
  padding-inline-start: 0;
`;

export const ListItem = styled.li`
  color: ${colors.lightAccent};
  font-size: 1.6rem;
  margin: 1rem 0;

  a {
    text-decoration: none;
    color: ${colors.lightAccent};
    font-size: 1.4rem;
    margin: 1rem 0;

    :hover {
      text-decoration: underline;
    }
  }
`;

export const PictureWrapper = styled.div`
  width: 100%;

  @media ${breakpoints.desktop} {
    width: 50%;
  }
`;
