import Styled from 'styled-components';
import { colors } from '../../styles/variables';

export const Container = Styled.div`
  position: relative;
  margin: 4rem 0;

  input:valid ~ label,
  textarea:valid ~ label {
    transform: translateY(-2rem);
    line-height: 1.5;
    font-size: 1.2rem;
  }
`;

export const Label = Styled.label`
  position: absolute;
  top: .5rem;
  left: 0;
  font-size: 1.6rem;
  pointer-events: none;
  color: ${colors.lightAccent};
  transition: all 0.2s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0.5;
`;

export const Input = Styled.input`
  font-size: 1.6rem;
  line-height: 1.6rem;
  padding-bottom: .5rem;
  width: 100%;
  color: ${colors.lightAccent};
  border: 0;
  background-color: ${colors.darkShade};
  border-bottom: .2rem solid ${colors.lightAccent};
`;

export const Textarea = Styled.textarea`
  resize: none;
	font-size: 1.6rem;
	line-height: 1.6rem;
  color: ${colors.lightAccent};
  width: 100%;
  height: auto;
  border: 0;
  background-color: ${colors.darkShade};
  border-bottom: .2rem solid ${colors.lightAccent};
`;

export const UnderLine = Styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: .2rem;
  will-change: transform,background-color;
  background-color: ${colors.lightShade};
  transform: scaleX(0);
  transform-origin: 100% 50%;
  transition: transform .35s;
  transition-timing-function: cubic-bezier(.25,.46,.45,.94);

  ${Input}:focus ~ &,
  ${Textarea}:focus ~ & {
    transform: scaleX(1);
    transform-origin: 0 50%;
    transition-timing-function: ease;
  }
`;

export const ErrorMessage = Styled.div`
  color: red;
  position: absolute;
  bottom: -2rem;
  font-size: 1.2rem;
`;
