import Styled from 'styled-components';
import Picture from '../Picture/Picture';
import { H2 } from '../../styles/commonStyles';

import { breakpoints, colors, fonts } from '../../styles/variables';

export const CardWrapper = Styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  text-align: center;
  width: 28rem;
  ${({ portrait }) => !portrait && 'height: 44rem'};

  ${({ url }) =>
    url &&
    `
    :hover {
      h2 {
        text-decoration: underline;
      }

      div {
        div {
          transform: scale(1.1);
        }
      }
    }
  `}

  @media ${breakpoints.phone} {
    width: 30rem;
    margin: 2rem;
  }
`;

export const ImageWrapper = Styled.div`
  overflow: hidden;

  div {
    transition: transform 0.2s ease-in-out;
  }
`;

export const StyledH2 = Styled(H2)`
  margin: 2rem 0 1rem;
  overflow: hidden;
  line-height: 1.3;
  color: ${colors.darkShade};
  font-family: ${fonts.merriweatherBold};
  font-size: 2rem;

  @media ${breakpoints.phone} {
    font-size: 2rem;
  }

  @media ${breakpoints.tabletPortrait} {
    font-size: 2rem;
  }

  @media ${breakpoints.tabletLandscape} {
    font-size: 1.8rem;
  }

  @media ${breakpoints.desktop} {
    font-size: 1.8rem;
  }

  @media ${breakpoints.desktopL} {
    font-size: 1.8rem;
  }
`;

export const StyledH3 = Styled(StyledH2)`
  padding: 0;
  margin: 0;
  font-size: 1.8rem;

  @media ${breakpoints.phone} {
    font-size: 1.8rem;
  }

  @media ${breakpoints.tabletPortrait} {
    font-size: 1.8rem;
  }

  @media ${breakpoints.tabletLandscape} {
    font-size: 1.6rem;
  }

  @media ${breakpoints.desktop} {
    font-size: 1.6rem;
  }

  @media ${breakpoints.desktopL} {
    font-size: 1.6rem;
  }
`;

export const StyledPicture = Styled(Picture)`
  height: 30rem;
`;
