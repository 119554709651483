import React from 'react';

import { Row, Column, ListBox, List, ListItem, PictureWrapper } from './contact.style';
import Picture from '../../components/Picture/Picture';
import Hero from '../../components/Hero/Hero';
import Card from '../../components/Card/Card';
import ContactForm from '../../components/ContactForm/ContactForm';
import GoogleMap from '../../components/GoogleMap/GoogleMap';

import { getContactPage } from './contact.query';

const Contact = () => {
  const data = getContactPage();
  const {
    contactHeroHeading,
    contactHeroImages,
    contactEmployees,
  } = data.allWordpressPage.edges[0].node.acf;

  const { infoBoard } = data;

  console.log(infoBoard);
  return (
    <>
      <Hero heading={contactHeroHeading} slides={contactHeroImages} />
      <Row>
        {contactEmployees.map(
          ({ employeePhoto, employeeDescription, employeeName, employeePosition }) => (
            <Card
              key={employeeName}
              fluid={employeePhoto.localFile.childImageSharp.fluid}
              heading={employeePosition}
              subheading={employeeName}
              description={employeeDescription}
              portrait
            />
          ),
        )}
      </Row>
      <Row>
        <PictureWrapper>
          <Picture fluid={infoBoard.childImageSharp.fluid} />
        </PictureWrapper>
      </Row>

      <Row>
        <Column>
          <ListBox>
            <List>
              <ListItem>Stara Piekarnia</ListItem>
              {/* <ListItem>ul. Reptowska 4</ListItem> */}
              {/* <ListItem>41-908 Bytom Miechowice</ListItem> */}
              <ListItem>
                Telefon: <a href="tel:+48322802200">+48 32 280 22 00</a>
              </ListItem>
              <ListItem>
                Tel.kom: <a href="tel:+48600023024">+48 600 023 024</a>
              </ListItem>

              <ListItem>
                Manager <a href="tel:+48783123583">+48 783 123 583</a>
              </ListItem>
              <ListItem>
                <a href="mail to:biuro@starapiekarnia.com">biuro@starapiekarnia.com</a>
              </ListItem>
              <ListItem>Nr Konta: Bank: ING Bank Śląski 50 1050 1230 1000 0022 8989 1281</ListItem>
              <ListItem>NIP: 498 010 12 31</ListItem>
            </List>
          </ListBox>
        </Column>
        <Column>
          <ContactForm />
        </Column>
      </Row>
      <GoogleMap />
    </>
  );
};

export default Contact;
