import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { Paragraph } from '../../styles/commonStyles';

import { CardWrapper, StyledH2, StyledH3, ImageWrapper, StyledPicture } from './Card.style';

const propTypes = {
  fluid: PropTypes.shape({}).isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  description: PropTypes.string,
  portrait: PropTypes.bool,
  url: PropTypes.string,
};

const defaultProps = {
  portrait: false,
  subheading: null,
  description: null,
  url: null,
};

const ConditionalLink = ({ condition, wrap, children }) => (condition ? wrap(children) : children);

const Card = ({ fluid, url, portrait, heading, description, subheading }) => (
  <CardWrapper portrait={portrait} url={!!url}>
    <ConditionalLink
      condition={!!url}
      wrap={children => (
        <Link aria-label={heading} to={url}>
          {children}
        </Link>
      )}
    >
      <ImageWrapper>
        <StyledPicture objectFit="contain" fluid={fluid} />
      </ImageWrapper>
      {heading && <StyledH2 description={description}>{heading}</StyledH2>}
      {subheading && <StyledH3>{subheading}</StyledH3>}
      {description && <Paragraph>{description}</Paragraph>}
    </ConditionalLink>
  </CardWrapper>
);

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

export default Card;
