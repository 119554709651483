import { useState, useEffect, useCallback } from 'react';

const VALUE = 'value';
const ERROR = 'error';

const REQUIRED_FIELD_ERROR = 'To pole jest wymagane';

const isObject = value => value !== null && typeof value === 'object';

const getPropValues = (stateSchema, prop) => {
  if (!isObject(stateSchema) || !prop) {
    throw new Error('Niepoprawne dane');
  }

  return Object.keys(stateSchema).reduce((accumulator, curr) => {
    accumulator[curr] = stateSchema[curr][prop];

    return accumulator;
  }, {});
};

const isRequiredField = (value, isRequired) => (!value && isRequired ? REQUIRED_FIELD_ERROR : '');

const useForm = (stateSchema = {}, stateValidatorSchema = {}, submitFormCallback) => {
  const [values, setValues] = useState(getPropValues(stateSchema, VALUE));
  const [errors, setErrors] = useState(getPropValues(stateSchema, ERROR));

  const [disable, setDisable] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  // Used to disable submit button if there's a value in errors
  // or the required field in state has no value.
  // Wrapped in useCallback to cached the function to avoid intensive memory leaked
  // in every re-render in component
  const validateErrorState = useCallback(() => Object.values(errors).some(error => error), [
    errors,
  ]);

  const validateState = useCallback(() => {
    const valuesCopy = {
      ...values,
      phoneNumber: undefined,
    };

    return Object.values(valuesCopy).some(value => value === '' || value === false);
  }, [values]);

  // Get a local copy of stateSchema
  useEffect(() => {
    // Disable button in initial render.
    setDisable(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // For every changed in our state this will be fired
  // To be able to disable the button
  useEffect(() => {
    if (isDirty) {
      setDisable(validateErrorState() || validateState());
    }
  }, [errors, isDirty, stateSchema, validateErrorState, validateState]);

  const validateForm = useCallback(
    (singleField = null) => {
      const currValues = singleField || values;

      Object.entries(currValues).forEach(([name, value]) => {
        const validator = stateValidatorSchema;

        // Making sure that stateValidatorSchema name is same in
        // stateSchema
        if (!validator[name]) return;

        const field = validator[name];

        let error = '';
        error = isRequiredField(value, field.required);
        // Prevent running this function if the value is required field
        if (error === '' && isObject(field.validator)) {
          const fieldValidator = field.validator;

          // Test the function callback if the value is meet the criteria
          const testFunc = fieldValidator.func;
          if (!testFunc(value, values)) {
            error = fieldValidator.error;
          }
        }
        setErrors(prevState => ({ ...prevState, [name]: error }));
      });
    },
    [stateValidatorSchema, values],
  );

  // Event handler for handling changes in input.
  const handleOnChange = useCallback(
    event => {
      setIsDirty(true);

      const { name } = event.target;
      const { value } = event.target;

      validateForm({ [name]: value });
      setValues(prevState => ({ ...prevState, [name]: value }));
    },
    [validateForm],
  );

  const handleResetForm = () => {
    setValues(getPropValues(stateSchema, VALUE));
    setValues(getPropValues(stateSchema, ERROR));
  };

  const handleOnSubmit = useCallback(
    event => {
      event.preventDefault();

      // Making sure that there's no error in the state
      // before calling the submit callback function
      validateForm();
      if (isDirty && !validateErrorState()) {
        submitFormCallback(values);
      }
    },
    [validateForm, isDirty, validateErrorState, submitFormCallback, values],
  );

  return {
    handleResetForm,
    handleOnChange,
    handleOnSubmit,
    values,
    errors,
    disable,
    setValues,
    setErrors,
  };
};

export default useForm;
