import { useStaticQuery, graphql } from 'gatsby';

// eslint-disable-next-line import/prefer-default-export
export const getContactPage = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { id: { eq: "861da105-2c08-5e75-94b7-3f8f52f9475c" } }) {
        edges {
          node {
            slug
            acf {
              contactHeroHeading
              contactHeroImages {
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 900) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              contactEmployees {
                employeeDescription
                employeeName
                employeePosition
                employeePhoto {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      infoBoard: file(relativePath: { eq: "info-board.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
