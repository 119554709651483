import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, SpinnerImg } from './Spinner.style';

const propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

const defaultProps = {
  width: null,
  height: null,
};

const Spinner = ({ width, height }) => (
  <Wrapper width={width} height={height}>
    <SpinnerImg src="/img/spinner.svg" />
  </Wrapper>
);

Spinner.propTypes = propTypes;
Spinner.defaultProps = defaultProps;

export default Spinner;
